@import "../../Scss/variable.scss";
@import "../../Scss/common-utilities.scss";


.get-quote-section {
    background-color: $grey;
    padding: $web-size-30;

    .quote {
        text-align: center;
        align-self: center;
        h5 {
            margin-bottom:$web-size-30;
        }

        .btn-quote {
            color: $primary;
            border-color: $primary;
            background-color: transparent;

            &:hover, &:focus { 
                color: $white;
                border-color: $primary;
                background-color: $primary;
            }
        }
    }

    p {
        margin-bottom: $web-size-0;
        padding-top: $web-size-10;
    }
}

@media (max-width: 768px) {
    .btn-quote {
        margin-top: $web-size-20;

    }
}