@import "../../Scss/variable.scss";

.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $web-size-50 $web-size-20;
    height: 80vh;
    width: 100%;
    background: url('../../Assets/coming-soon.jpg') no-repeat center center;
    background-size: cover;
    color: #fff;

    h3 {
        font-size: $web-size-40;
        color: $white;
        margin-bottom: $web-size-20;
    }
      
    p {
        font-size: $web-size-20 !important;
        color: $white;
    }
  }
  

  