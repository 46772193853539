@import "../../Scss/variable.scss";

.service-section {
  
  h3 {
    font-size: $web-size-30;
    color: $text-color;
  }

  h6 {
    font-size: $web-size-16 !important;
  }

  p {
    font-size: $web-size-14 !important;
    span {
      font-size: $web-size-12 !important;
      font-weight: 500;
    }
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background: color-mix(in srgb, $primary, transparent 96%);
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.12);
    color: $text-color;
  }

  .services-menu {
    display: flex;
    align-items: center;
    
    span {
      svg { 
        background-color: $white;
        color:  $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $web-size-10;
        height: $web-size-40;
        width: $web-size-40;
        font-size: $web-size-20;
        flex-shrink: $web-size-0;
        border-radius: $web-size-50;
        padding: $web-size-4;
        box-shadow: 0 $web-size-2 $web-size-4 rgba(0, 0, 0, 0.1);
      }
    }

    h6 {
      font-size: $web-size-20;
      color: $text-color;
    }
  }

  .service-content {
    .service-image {
      width: 500px
    }

    p {
      text-align: justify;
    }
  }
}

@media (max-width: 768px) {
  .service-section {
    .service-content {
      .service-image {
        width: 100% !important;
      }
    }

    .services-menu {
      margin-bottom: $web-size-20;
    }
  }
}

