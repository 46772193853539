@import "../../Scss/variable.scss";

.footer {
    background-color: $black;
    color: $grey-light;

    .footer-section-1 {
        padding: $web-size-30  $web-size-20  $web-size-0  $web-size-20;

        .row {
            padding: $web-size-0 $web-size-20;
    
            div {
                margin-bottom:$web-size-10;
            }
        }
    
        .footer-nav {
            .right-arrow-icon {
                color: $primary;
                font-size: $web-size-16;
            }
        }
    
        .nav-link {
            color: $grey-light !important;
            font-size: $web-size-12;
    
            &:hover, &:focus {
                color: $primary !important;
            }
        }
    
        h4 {
            font-size: $web-size-20; 
            color: $white;
            margin-bottom: $web-size-20;
          }
    
        h5 {
          font-size: $web-size-18; 
          color: $white; 
        }
    
        p {
            font-size: $web-size-12;
            line-height: 1.8;
        }    
    }
    
    .quote {
        h5 {
            margin-bottom:$web-size-30;
        }

        .btn-quote {
            color: $grey-light;
            border-color: $grey-light;
            background-color: transparent;

            &:hover, &:focus { 
                color: $primary;
                border-color: $primary;
            }
        }
    }

    .footer-section-2 {
        background-color: $footer-color;
        padding: $web-size-20;

        p {
            margin-bottom: $web-size-0;
        }

        span {
            color: $white;
        }

        .social {
            display: flex;
            justify-content: flex-end;

            .social-icon {
                height: $web-size-30;
                width: $web-size-30;
                font-size: $web-size-18;
                background-color: $primary;
                margin-right: $web-size-20;
                border-radius: $border-radius-round;
                text-align: center;
                color: $white;
            }
        }
        
    }
}

@media (max-width: 768px) {
    .footer {
        .footer-section-1 {
            padding: $web-size-30  $web-size-0  $web-size-0  $web-size-0;
        }
        .quote {
            .btn-quote {
                margin-top: $web-size-0;
            }
        }
    }
}
