// Colors
$primary: #f03c02;
$primary-light: #fd5c28;
// $primary: #303693;
// $primary: #0a58ca;
// $secondary: #1a98ce;
$black: #000000;
$white: #ffffff;
$grey: #f3f1f0;
$grey-light: #b4aca8;
$grey-dark: #1c1715b3;
$footer-color: #14100f;
$social-media-color: #417ed7;
$text-color: #4d4643;
$text-color-light: #90847f;
$bg-color-light-grey: #f8f9fa;

// Size in px
$web-size-0: 0;
$web-size-2: 2px;
$web-size-4: 4px;
$web-size-5: 5px;
$web-size-10: 10px;
$web-size-12: 12px;
$web-size-14: 14px;
$web-size-16: 16px;
$web-size-18: 18px;
$web-size-20: 20px;
$web-size-22: 22px;
$web-size-30: 30px;
$web-size-34: 34px;
$web-size-40: 40px;
$web-size-50: 50px;
$web-size-60: 60px;


$border-radius-round: 50%;

$theme-colors: (
  'info': tomato,
  'danger': teal
);
