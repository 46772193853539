@import "../../Scss/variable.scss";

.footer {
    background-color: $black;
    color: $white;

    .nav-section {
        background-color: $footer-color;
        padding: $web-size-20;

       h4 {
            margin-bottom: 0;
       }

        .navigation {
            display: flex;
            justify-content: flex-end;
            align-items: center;
             p {
                span {
                    color: $primary
                }
             }
        }
        
    }
}