@import "../../Scss/variable.scss";

.carousel-height {
    max-height: 60%;
}

.carousel-caption {
    background-color: $grey-dark;
    padding: $web-size-20;
    color: $white;
    border-top: $web-size-5 solid $primary;
    text-align: left !important;
    bottom:20% !important;

    h5 {
        font-size: $web-size-30;
    }
}

@media (max-width: 768px) {
    .carousel-height {
        height: 250px;
    }
    .carousel-img {
        height: 250px;
    }
    .carousel-caption {
        padding: $web-size-10;

        h5 {
            font-size: $web-size-14;
        }

        p {
            font-size: $web-size-10 !important;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
