@import "../../Scss/variable.scss";

.service-card-section {
    padding-top: $web-size-10;

    .service-card-section-row {
        .single-card {
            margin-bottom: $web-size-30;
            height:320px;

            
            .card {
                background-color: $white;
                box-shadow: 0px 5px 90px 0px rgba(0, 0, 0, 0.1);
                padding: $web-size-40 $web-size-20;
                transition: all ease-in-out 0.3s;
                border-radius: $web-size-20;
                border-bottom: $web-size-5 solid var(--surface-color);
                height: 100%;

                .card-body {
                    .card-title {
                        font-weight: 700;
                        font-size: $web-size-20;
                        line-height: 1.5;
                        margin-top: $web-size-20;
                    }

                    .card-text {
                        font-size: 14px !important;
                    }

                    .card-icon {
                        height: $web-size-50;
                        width: $web-size-50;
                        padding: $web-size-10;
                        font-size: $web-size-16;
                        color: $white;
                        background-color: $primary;
                        border-radius: $border-radius-round;
                    }
                }
            }

            &:hover {
                .card {
                    transform: translateY(-10px);
                    border-bottom: 5px solid $primary !important;

                    .card-title {
                        color: $primary;
                    }
                }
            }
        }
    }

    .row-1 {
        padding-top: $web-size-30;
    }

    .row-2 {
        padding-bottom: $web-size-30;
    }
   
}