
@import "../../Scss/variable.scss";

.about-section{
    h3 {
        font-size: $web-size-30;
        color: $text-color;
    }

    p {
        font-size: $web-size-14 !important;
    }

    .about-menu {
        h5 {
            font-size: $web-size-16;
            color: $text-color;
        }

        .menu-icon{
            font-size: $web-size-34;
            color: $primary;
        }

        p {
            font-size: 12px !important;
            color: $text-color-light;
            text-align: justify;
        }
    }

}