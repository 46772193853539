@import "../../Scss/variable.scss";

.google-map {

    iframe {
        border: 0;
        width: 100%;
        height: 350px;
    }
}

.address {
    .address-wrapper {
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
        padding: 30px;

        .address-card {
            display: flex;

            .address-icon {
                font-size: $web-size-20;
                color: #f03c02;
                float: left;
                width: $web-size-40;
                height: $web-size-40;
                background: #ffeee8;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: $web-size-50;
                padding: $web-size-10;
                margin-right: $web-size-10;
                transition: all 0.3s ease-in-out;
            }

            p {
                a {
                    color: $text-color;
                    text-decoration: none;
                }
            }
        }

        .address-col {
            &:hover, &:active {
                .address-card {
                    .address-icon {
                        background: #f03c02;
                        color: #fff;
                    }
                }
            }
        }

        
    }
}